import React from 'react';
import PropTypes from 'prop-types';
import Layout1 from './layout1';
import MainLayout from './mainLayout';

const Layout = ({ children, pageContext }) => {
  switch (pageContext?.layout) {
    case 'layout1':
      return <Layout1>{children}</Layout1>;
    default:
      return <MainLayout>{children}</MainLayout>;
  }
};

Layout.propTypes = {
  children: PropTypes.node,
  pageContext: PropTypes.object,
};

export default Layout;