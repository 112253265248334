import * as React from 'react';
import PropTypes from 'prop-types';
import { AppContextProvider, AppContext } from './context/AppContext';
import { I18nProvider } from './i18n';
import { CssBaseline } from '@mui/material';

const MainContainer = ({ children }) => {
  return (
    <AppContextProvider>
      <AppContext.Consumer>
        {(appContext) => (
          <I18nProvider locale={appContext[0]?.language}>
            <CssBaseline />
            {children}
          </I18nProvider>
        )}
      </AppContext.Consumer>
    </AppContextProvider>
  );
};

MainContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MainContainer;
