import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { Stack } from '@mui/material';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Spinner from '../spinner';

const PDFViewer = ({ intl: { formatMessage }, pdfUrl }) => {
  const [PDFComponent, setPDFComponent] = useState(null);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    let isMounted = true;

    import('react-pdf').then(({ Document, Page, pdfjs }) => {
      if (isMounted) {
        pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
        setPDFComponent(() => ({ Document, Page }));
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const Loading = (
    <Stack spacing={2} alignItems="center" width={1} my={3}>
      <Spinner isLoading size="large" />
      <span>{formatMessage({ id: 'pdfLoading' })}</span>
    </Stack>
  );

  if (!PDFComponent) {
    return Loading;
  }
  const { Document, Page } = PDFComponent;

  return (
    <Stack id="pdf-container" alignItems="center" mb={2} width={1}>
      <Document
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) => console.error('Error PDF:', error)}
        loading={Loading}
        error={formatMessage({ id: 'pdfError' })}
        // options={{
        //   cMapUrl: 'cmaps/',
        //   cMapPacked: true,
        // }}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={document.getElementById('pdf-container')?.clientWidth || 800}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        ))}
      </Document>
    </Stack>
  );
};

PDFViewer.propTypes = {
  intl: PropTypes.object.isRequired,
  pdfUrl: PropTypes.string,
};

export default injectIntl(PDFViewer);
