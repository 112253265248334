import React from 'react';
import PropTypes from 'prop-types';
import { Footer } from '@components';

const Layout1 = ({ children }) => {
  return (
    <main>
      {children}
      <Footer />
    </main>
  );
};

export default Layout1;

Layout1.propTypes = {
  children: PropTypes.node,
};
