import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Contact, Footer } from '@components';

const MainLayout = ({ children }) => {
  return (
    <main>
      <Navbar />
      {children}
      <Contact />
      <Footer />
    </main>
  );
};

export default MainLayout;

MainLayout.propTypes = {
  children: PropTypes.node,
};
