import React from 'react';
import { PropTypes } from 'prop-types';
import * as styles from './spinner.module.scss';

const Spinner = ({ isLoading = false, size = 'small' }) => {
  if (isLoading) {
    return (
      <small
        className={`${styles.spinner} ${size === 'large' ? styles.large : ''}`}
      ></small>
    );
  } else {
    return null;
  }
};

Spinner.propTypes = {
  isLoading: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large']),
};

export default Spinner;
